import { useEffect } from "react"

const IndexPage = () => {
  useEffect(() => {
    if (window && window.location && window.location.href) {
      window.location.href = "/de"
    }
  }, [])
  return null
}

export default IndexPage
